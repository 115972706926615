@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1c1d23 !important;
  word-break: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.logo-animation{
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 10px;
  background: linear-gradient(to right, #C540CA 20%, #8126CB 40%, #FFF 60%, #FFD507 80%, #FF6330 100%);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 1.5s linear infinite;
}
@keyframes shine {
  to {
    background-position: 200% center;
  }
}
.title-animation{
  font-size: 56px;
  font-weight: 600;
  color: transparent;
  background-image: linear-gradient(to right ,#6dd8d4, #f389c0, #d8bd84, #fd8ff5, #fff);
  -webkit-background-clip: text;
  background-clip: text;    
  background-size: 200%;
  background-position: -200%;
  animation: animated-gradient 2s infinite alternate-reverse; 
  line-height: 68px;
  margin: 24px 0;
}
@keyframes animated-gradient{
  to{
    background-position: 200%;
  }
}
.menu-bg {
  background: #fff;
  border-radius: 10px;
  padding: 10px 20px 10px 25px;
}
.menu-animation{
  background: linear-gradient(to right, #ab83ff 20%, #ab08a6 40%, #ab08a6 60%, #ab83ff 80%);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 1s linear infinite;
}
.menu-animation div{
  font-size: 18px;
}
@keyframes shine {
  to {
    background-position: 200% center;
  }
}
.cardBox {
  width: 180px;
  height: 46px;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
  margin-bottom: 30px;
}
.card-animation {
  position: absolute;
  width: 133px;
  height: auto;
  background: linear-gradient(64.83deg, #30304e 15%, #27283c 60%);
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  padding: 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
}
.cardBox::before {
  content: "";
  position: absolute;
  width: 286px;
  height: 40px;
  background: #40E0D0;
/* background: -webkit-linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);
background: linear-gradient(to right, #FF0080, #FF8C00, #40E0D0); */
background: linear-gradient(to right, #00b1aa, #d7006f, #00ad9b, #FF0080);
  animation: glowing01 5s linear infinite;
  transform-origin: center;
  animation: glowing 3s linear infinite;
}
.card-content{
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  width: 100%;
  background: radial-gradient(circle at 10% 20%, rgb(69, 86, 102) 0%, rgb(34, 34, 34) 90%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow-animation{
  transform: rotate(-40deg);
  width: 20px;
  margin-top: 12px;
}
.card-title{
  margin: 0;
  padding: 20px 15px 9px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 17px;
}
.card-p{
  font-size: 14px;
  padding: 12px;
}
@keyframes glowing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.counter{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.counter-panel{
  padding: 10px 20px;
  background: linear-gradient(to right, #3c1053, #ad5389);
  border-radius: 10px;
  margin-right: 20px;
  display: inline-block;
  color: #fff;
  font-weight: 600;
  width: 65px;
  height: 80px;
  position: relative;

}
.counter-panel-border::after{
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(90deg, rgba(255,255,255,0)    0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}
.counter-text{
  font-size: 32px;
}
.panel-bottom {
  bottom: 15px;
  left: 0;
  position: absolute;
  right: 0;
}
.language-dropdown .gt_switcher_wrapper{
  position: absolute !important;
  top: 38px !important;
  z-index: 999999;
  right: 70px !important;
  left: auto !important;
  z-index: 2 !important;
}
.language-dropdown .gt_container--hich1m .gt_switcher .gt_selected a {
  border: transparent;
  color: #fff;
  padding: 5px 5px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  line-height: 15px;
  align-items: center;
  width: 131px;
}
.language-dropdown .gt_container--hich1m .gt_switcher .gt_selected {
  background: #000;
  border-radius: 5px;
  border: 1px solid #fff;
}
.language-dropdown .gt_container--hich1m .gt_switcher .gt_selected a:hover {
  background: transparent;
}
.language-dropdown .gt_container--hich1m .gt_switcher {
  width: 143px;
}
.language-dropdown .gt_container--hich1m .gt_switcher .gt_selected a:after {
  right: 11px;
  background-size: 18px;
  top: 5px;
  background-image: url('./down-arrow.png') !important;
}
.language-dropdown .gt_container--hich1m .gt_switcher a img {
  margin: 0 6px 0 0;
  opacity: 9;
}
.language-dropdown .gt_container--hich1m .gt_switcher .gt_option {
  border-radius: 5px;
  background: #000;
}
.language-dropdown .gt_container--hich1m .gt_switcher .gt_option {
  width: 141px;
}
.language-dropdown .gt_container--hich1m .gt_switcher .gt_option a {
  display: flex;
  align-items: center;
  line-height: 15px;
  color: #fff;
}
.gt_container--hich1m .gt_switcher .gt_option a:hover {
  color: #000;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  left: -14px !important;
  right: auto !important;
  top: 26px !important;
}
.display-block{
  display: block !important;
  padding: 0 15px !important;
}
.display-block p{
  margin-bottom: 3px;
  font-size: 16px;
  letter-spacing: 0.5px;
}
@media all and (device-width: 1300px) and (device-height: 1200px) and (orientation:landscape) {
  .language-dropdown .gt_switcher_wrapper {
    top: 102px !important;
    right: 36px !important;
}
}
@media (max-width:1199px) and (min-width:1024px) {
  .language-dropdown .gt_switcher_wrapper {
    top: 100px !important;
    right: 38px !important;
}
  }
@media (max-width:1024px) and (min-width:992px) {
  .menu-bg {
    padding: 10px 10px 10px 10px;
}
.menu-animation div {
  font-size: 16px;
  margin-right: 20px;
}
.language-dropdown .gt_switcher_wrapper {
  top: 104px !important;
  right: 43px !important;
  }
  .md-panel{
    width: 90% !important;
  }
  .trade-btn{
    width: 50%;
  }
  .counter-panel {
    height: 66px;
}
.panel-bottom {
  font-size: 14px;
}
.counter-text {
  font-size: 28px;
}
}
@media (max-width: 991px) {
  .menu-bg {
    background: #fff;
    padding: 0 0 0 15px;
}
.menu-animation div {
  font-size: 16px;
  margin-right: 20px;
}
.trade-btn{
  width: 100% !important;
}
.sm-wrap{
  flex-wrap: wrap;
  gap: 10px;
}
.language-dropdown .gt_switcher_wrapper {
  top: 80px !important;
  right: 42px !important;
}
.md-panel{
  width: 90% !important;
  margin: 0 auto;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.counter-text {
  font-size: 25px;
}
.counter-panel {
  height: 66px;
}
.panel-bottom {
font-size: 14px;
}
}
@media (max-width: 768px) {
  .menu-bg {
    background: #000;
    padding: 0;
}
.language-dropdown .gt_switcher_wrapper {
  right: 23px !important;
}
}
@media (max-width: 767px) {
  .menu-bg {
    background: #000;
    padding: 0;
}
.xs-reverse {
  flex-direction: column-reverse !important;
}
.card-content {
  top: 45%;
}
.cardBox {
  width: 158px;
  height: 44px;
}
.card-animation {
  width: 112px;
}
.card-title {
  padding: 12px 15px 9px;
}
.arrow-animation {
  margin-top: 7px;
}
/* .counter-text {
  font-size: 28px;
  width: 38px;
} */
.panel-bottom {
  font-size: 14px;
}
.counter {
  gap: 5px;
  padding: 0 5px;
}
.counter-panel {
  margin-right: 0;
  height: 70px;

}
.language-dropdown .gt_switcher_wrapper {
  top: 67px !important;
  right: 20px !important;
}
}